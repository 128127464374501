export const sounds = {
  rooster: {
    id: 'rooster',
    mp3: '/sounds/rooster.mp3',
    ogg: '/sounds/rooster.ogg',
  },
  schoolBell: {
    id: 'schoolBell',
    mp3: '/sounds/school_bell.mp3',
    ogg: '/sounds/school_bell.ogg',
  },
  windChimes: {
    id: 'windChimes',
    mp3: '/sounds/wind_chimes.mp3',
    ogg: '/sounds/wind_chimes.ogg',
  },
};
