import React from 'react';
import {Provider} from 'react-redux';
import createStore from 'state/store';
import PropTypes from 'prop-types';

const ReduxProvider = ({children}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;

ReduxProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
