import {configureStore} from '@reduxjs/toolkit';
import counterReducer from 'state/counterSlice';
import pomodoroReducer from 'state/pomodoro';
import audioReducer from 'state/audio';
import notificationsReducer from 'state/notifications';

const createStore = () =>
  configureStore({
    reducer: {
      audio: audioReducer,
      counter: counterReducer,
      pomodoro: pomodoroReducer,
      notifications: notificationsReducer,
    },
  });

export default createStore;
