import {createSlice} from '@reduxjs/toolkit';
import uniqueId from 'lodash/uniqueId';
import remove from 'lodash/remove';
import {getLocation} from 'utils';

const initialState = {};
const uniqueIdPrefix = 'notification_';

export const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      const {type, heading, message, message2} = action.payload;
      const {pathname} = getLocation();

      const notification = {
        id: uniqueId(uniqueIdPrefix),
        type,
        heading,
        message,
        message2,
      };

      if (state[pathname]) {
        state[pathname].push(notification);
        return;
      }

      state[pathname] = [notification];
    },
    removeNotification: (state, action) => {
      const {id: notificationId} = action.payload;
      const {pathname} = getLocation();

      if (state[pathname]) {
        remove(state[pathname], ({id}) => notificationId === id);
      }
    },
    clearAllNotifications: () => {
      return {
        ...initialState,
      };
    },
  },
});

export default slice.reducer;
export const {actions} = slice;
