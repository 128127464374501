import {addLeadingZero} from 'utils';
import moment from 'moment';

export const getSeconds = minutes => minutes * 60;
export const getMinutes = seconds => Math.floor(seconds / 60);
export const getMinutesAndSeconds = seconds => {
  const minutes = getMinutes(seconds);
  const secs = seconds - minutes * 60;
  return {
    minutes,
    seconds: secs,
    minutesAndSeconds: `${addLeadingZero(minutes)}:${addLeadingZero(secs)}`,
  };
};

export const getISODateString = ddMMyyyy => moment(ddMMyyyy, 'DD/MM/YYYY').toISOString();
export const getDateString = isoString => moment(isoString).format('DD/MM/YYYY');
