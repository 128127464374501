import {createSlice} from '@reduxjs/toolkit';
import {sounds} from 'utils/static';
import {getSeconds} from 'utils/date';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

//Session Types
const WORK = 'WORK';
const SMALL_BREAK = 'SMALL_BREAK';
const BIG_BREAK = 'BIG_BREAK';

export const sessionTypes = {
  WORK,
  SMALL_BREAK,
  BIG_BREAK,
};

//Timer status
const TIMER_STOPPED = 'TIMER_STOPPED';
const TIMER_PAUSED = 'TIMER_PAUSED';
const TIMER_RUNNING = 'TIMER_RUNNING';
export const timerStatuses = {
  TIMER_STOPPED,
  TIMER_PAUSED,
  TIMER_RUNNING,
};

export const soundOptions = map(sounds, ({id}) => ({
  label: startCase(id),
  value: id,
}));

const initialState = {
  playSessionEndSound: true,
  [WORK]: {
    sessionEndSound: sounds.schoolBell.id,
    duration: 25,
    count: 0,
  },
  [SMALL_BREAK]: {
    sessionEndSound: sounds.windChimes.id,
    duration: 5,
    count: 0,
  },
  [BIG_BREAK]: {
    sessionEndSound: sounds.rooster.id,
    duration: 15,
    count: 0,
    interval: 4,
  },
  currentSession: WORK,
  remainingTime: getSeconds(25),
  timerStatus: TIMER_STOPPED,
};

export const slice = createSlice({
  name: 'pomodoro',
  initialState,
  reducers: {
    restoreDefaultSettings: () => {
      return {
        ...initialState,
      };
    },
    handleInputChange: (state, action) => {
      const {name, value, checked} = action.payload;

      if (name === 'playSessionEndSound') {
        state[name] = checked;
        return;
      }
      const [session, key] = name.split('.');

      state[session][key] = value;
    },
    incrementCurrentSessionCount: state => {
      const {currentSession} = state;

      state[currentSession].count += 1;
    },
    setNextSession: state => {
      const {currentSession} = state;

      if (currentSession === BIG_BREAK || currentSession === SMALL_BREAK) {
        state.currentSession = WORK;
        return;
      }
      const isBigBreak = Number.isInteger(
        (state[WORK].count + 1) / state[BIG_BREAK].interval
      );
      state.currentSession = isBigBreak ? BIG_BREAK : SMALL_BREAK;
    },
    setRemainingTime: (state, action) => {
      if (action && action.payload) {
        const {remainingTime} = action.payload;

        if (remainingTime || Number.isInteger(remainingTime)) {
          state.remainingTime = remainingTime;
          return;
        }
      }

      const {currentSession} = state;
      state.remainingTime = getSeconds(state[currentSession].duration);
    },
    pauseTimer: state => {
      state.timerStatus = TIMER_PAUSED;
    },
    stopTimer: state => {
      state[WORK].count = 0;
      state[SMALL_BREAK].count = 0;
      state[BIG_BREAK].count = 0;
      state.currentSession = WORK;
      state.remainingTime = getSeconds(state[WORK].duration);
      state.timerStatus = TIMER_STOPPED;
    },
    startTimer: state => {
      state.timerStatus = TIMER_RUNNING;
    },
    decrementRemainingTime: state => {
      state.remainingTime -= 1;
    },
  },
});

export default slice.reducer;
export const {actions} = slice;
