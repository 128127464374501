import {createSlice} from '@reduxjs/toolkit';
import {sounds} from 'utils/static';

const AUDIO_PLAYING = 'AUDIO_PLAYING';
const AUDIO_PAUSED = 'AUDIO_PAUSED';
const AUDIO_STOPPED = 'AUDIO_STOPPED';

export const audioStatuses = {
  AUDIO_PLAYING,
  AUDIO_PAUSED,
  AUDIO_STOPPED,
};

let audio;

export const slice = createSlice({
  name: 'audio',
  initialState: {
    src: null,
    status: AUDIO_STOPPED,
  },
  reducers: {
    setAudioSource: (state, action) => {
      state.src = sounds[action.payload.src].ogg;
    },
    playAudio: state => {
      state.status = AUDIO_PLAYING;

      if (audio && audio.play) {
        audio.play();
        return;
      }
      audio = new Audio();
      audio.src = state.src;
      audio.play();
    },
    pauseAudio: state => {
      state.status = AUDIO_PAUSED;
      if (audio && audio.pause) {
        audio.pause();
      }
    },
    stopAudio: state => {
      state.src = null;
      state.status = AUDIO_STOPPED;
      if (audio && audio.pause) {
        audio.pause();
        audio = null;
      }
    },
  },
});

export const {actions} = slice;

export default slice.reducer;
