export const getLocation = () => (typeof window !== 'undefined' ? window.location : '');
export const isBrowser = typeof window !== 'undefined';
export const getHomeUrl = () => {
  const {protocol, host} = window.location;
  return `${protocol}//${host}`;
};
export const getPercentageChange = (remaining, total, percentage = false) => {
  const diff = total - remaining;
  if (!percentage) {
    return diff / total;
  }
  return (diff / total) * 100;
};
export const addLeadingZero = str => {
  str = `${str}`;

  if (str.length === 1) {
    return `0${str}`;
  }
  return str;
};

export const getPathPrefix = () => `${__PATH_PREFIX__}/`;
