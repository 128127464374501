import ApolloClient from 'apollo-boost';
import auth from 'utils/auth';

export const isBrowser = typeof window !== 'undefined';

let client = {};

if (isBrowser) {
  client = new ApolloClient({
    uri: process.env.GATSBY_BJS_API,
    request: operation => {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${auth.idToken}`,
        },
      });
    },
  });
}

export {client};
