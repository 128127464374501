import React, {Component, Fragment} from 'react';
import {ApolloProvider} from 'react-apollo';
import {client} from 'components/context/apollo';
import ReduxProvider from 'components/context/redux-provider';
import PropTypes from 'prop-types';
import auth from 'utils/auth';
import {isBrowser} from 'utils/index';

class SessionCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  handleCheckSession = () => {
    this.setState({loading: false});
  };

  componentDidMount() {
    if (isBrowser) {
      auth.silentAuth(this.handleCheckSession);
    }
  }

  render() {
    const {children} = this.props;
    const {loading} = this.state;

    if (loading) {
      return null;
    }
    return <Fragment>{children}</Fragment>;
  }
}

export const wrapRootElement = ({element}) => (
  <SessionCheck>
    <ApolloProvider client={client}>
      <ReduxProvider>{element}</ReduxProvider>
    </ApolloProvider>
  </SessionCheck>
);

wrapRootElement.propTypes = {
  element: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

SessionCheck.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
