// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-blog-post-jsx": () => import("./../src/components/templates/blog-post.jsx" /* webpackChunkName: "component---src-components-templates-blog-post-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-app-jsx": () => import("./../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-archive-login-jsx": () => import("./../src/pages/archive_login.jsx" /* webpackChunkName: "component---src-pages-archive-login-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-callback-jsx": () => import("./../src/pages/callback.jsx" /* webpackChunkName: "component---src-pages-callback-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pomodoro-timer-jsx": () => import("./../src/pages/pomodoro-timer.jsx" /* webpackChunkName: "component---src-pages-pomodoro-timer-jsx" */),
  "component---src-pages-theme-jsx": () => import("./../src/pages/theme.jsx" /* webpackChunkName: "component---src-pages-theme-jsx" */)
}

