import auth0js from 'auth0-js';
import {isBrowser} from 'utils/index';
import {getHomeUrl} from 'utils';

// Only instantiate Auth0 if we’re in the browser.
const webAuthOpts = {
  domain: process.env.GATSBY_AUTH0_DOMAIN,
  clientID: process.env.GATSBY_AUTH0_CLIENTID,
  redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
  audience: `https://${process.env.GATSBY_AUTH0_DOMAIN}/userinfo`,
  responseType: 'token id_token',
  scope: 'openid profile email',
};

//Class based auth
class Auth {
  constructor() {
    this.auth0 = isBrowser ? new auth0js.WebAuth(webAuthOpts) : {};
    this.accessToken = null;
    this.idToken = null;
    this.profile = {};
    // set the time that the id token will expire at
    this.expiresAt = null;
  }

  login = e => {
    if (!isBrowser) {
      return;
    }
    e && e.preventDefault();
    this.auth0.authorize();
  };

  logout = e => {
    e && e.preventDefault();
    this.clearTokens();

    this.auth0.logout({returnTo: getHomeUrl()});
  };

  clearTokens = () => {
    this.accessToken = null;
    this.idToken = null;
    this.profile = {};
    this.expiresAt = null;
  };
  setTokens = authResult => {
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    this.expiresAt = authResult.idTokenPayload.exp * 1000;
    console.log('Authorization: ');
    console.log(`{"Authorization": "bearer ${authResult.idToken}"}`);
  };
  handleAuthentication = callback => {
    if (!isBrowser) {
      return;
    }

    this.auth0.parseHash(this.setSession(callback));
  };

  //From gatsby store
  silentAuth = callback => {
    if (!isBrowser) {
      return;
    }

    if (!this.isAuthenticated()) {
      callback();
      return;
    }

    this.auth0.checkSession({}, this.setSession(callback));
  };

  isAuthenticated = () => {
    if (!isBrowser) {
      return;
    }

    return this.idToken;
  };

  setSession = callback => (err, authResult) => {
    if (!isBrowser) {
      return;
    }

    if (err) {
      console.error(err);
      if (callback) {
        callback(null, err);
      }
      return;
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      this.setTokens(authResult);
      if (callback) {
        callback(this.profile);
      }
    }
  };
}

const auth = new Auth();
export default auth;
